
<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.formFieldsDefault', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-alert
        v-if="!allowReservations"
        outlined
        type="warning"
        text
        style="margin: 0 20px;"
      >
        {{$t('booking.formFieldsAllowReservationsMessage', locale)}}
      </v-alert>
      <form-fields
        :locale="locale"
        :onChange="handleChangeIsModified"
        :formFields="formFields"
        :workspaceID="workspaceID"
        :pac="isPaxAsCount"
        :isWorkspaceForm="true"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
//import api from '@/services/api'
import FormFields from '@/components/formFields/Index'
export default {
  name: 'FormFieldsDefaultForm',
  components: {
    FormFields,
  },
  props: {
    formFields: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
    allowReservations: {
      type: Boolean,
      default: false,
    },
    workspaceID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    isPaxAsCount () {
      return this.formFields ? this.formFields.paxAsCount : null
    },
  },
  methods: {
    handleExpand () {
    },
    handleChangeIsModified (v) {
      this.onChangeData(v)
    },
  },
}
</script>

